export default {
  state:{
    sidebarLinks:[]
  },
  mutations:{
    setSidebarLinks(state, links){
      state.sidebarLinks = links;
    }
  }
}
