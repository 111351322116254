
export const models = {
  name:null,
}
export const options = {
}

export const fields = [
  {
    label:'Name',
    name:'name',
    placeholder:'Enter the name',
    type:'text',
    value:'',
    validation_messages:{
      required: 'This field is required'
    }
  }
];
