<template>
  <div class="card card-user mb-0">
    <div class="card-body pb-0" style="min-width: 200px; z-index: 999999">
      <div class="author">
          <img v-if="$store.state.user.media[0]" :src="__asset($store.state.user.media[0].src , 200, 160)" alt="">
          <img v-else class="avatar border-gray" src="/static/img/faces/face-0.jpg" alt="...">
          <h3 class="title mt-2 mb-0 p-0" :style="{fontSize: '14px'}">{{ $store.state.user.name }}</h3>
          <h5 class="mt-2" style="font-size: 12px" >{{ $store.state.user.email }}</h5>
          <a class="btn btn-warning w-100" href="#" @click="logout">Logout</a>
      </div>
    </div>
  </div>
</template>
<script>
  import {logoutUser} from "@/API/UsersAPIs";

  export default {
    methods:{

      async logout(){
        await logoutUser();
        this.$router.push({name:'Login'})
      }
    }

  }

</script>
<style>

</style>
