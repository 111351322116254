import {__localesModel, __localizedField} from "@/util/locales";

export const models = {
  locales: __localesModel,
  branch_id: null,
  business_id: null
}
export const options = {
}

export const fields = [
  __localizedField
];
