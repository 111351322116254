<template>
  <navbar v-model="showNavbar">
    <div class="navbar-wrapper">
      <div class="navbar-minimize">
        <button id="minimizeSidebar" class="btn btn-icon btn-round" @click="minimizeSidebar">
          <i class="nc-icon nc-minimal-right text-center visible-on-sidebar-mini"></i>
          <i class="nc-icon nc-minimal-left text-center visible-on-sidebar-regular"></i>
        </button>
      </div>
      <div class="navbar-toggle">
        <navbar-toggle-button @click.native="toggleSidebar">

        </navbar-toggle-button>
      </div>
      <a class="navbar-brand" href="#">finemenu Admin Dashboard</a>
    </div>

    <template slot="navbar-menu">
      <form>
        <div class="input-group no-border">
          <input type="text" value="" class="form-control" placeholder="Search...">
          <div class="input-group-append">
            <div class="input-group-text">
              <i class="nc-icon nc-zoom-split"></i>
            </div>
          </div>
        </div>
      </form>
      <ul class="navbar-nav">
        <li class="nav-item">
          <QRHeader />
        </li>
        <li>
          <drop-down icon="nc-icon nc-settings-gear-65" tag="li"
                     position="right"
                     direction="none"
                     class="nav-item btn-rotate">
            <a slot="title"
               slot-scope="{isSettingsOpen}"
               class="nav-link cursor-pointer"
               data-toggle="dropdown"
               aria-haspopup="true"
               :aria-expanded="isSettingsOpen">
              <i class="nc-icon nc-settings-gear-65"></i>
            </a>
            <user-card></user-card>
          </drop-down>
        </li>
      </ul>
    </template>
  </navbar>
</template>
<script>
  import { Navbar, NavbarToggleButton } from 'src/components/UIComponents'
  import {logoutUser} from "@/API/UsersAPIs";
  import QRHeader from "@/components/Dashboard/Common/QRHeader.vue";
  import UserCard from "@/components/Dashboard/Views/Pages/UserProfile/UserCard.vue";

  export default {
    components: {
      UserCard,
      QRHeader,
      Navbar,
      NavbarToggleButton
    },
    data() {
      return {
        activeNotifications: false,
        showNavbar: false
      }
    },
    methods: {
      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      },
      toggleNotificationDropDown() {
        this.activeNotifications = !this.activeNotifications
      },
      closeDropDown() {
        this.activeNotifications = false
      },
      toggleSidebar() {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
      },
      hideSidebar() {
        this.$sidebar.displaySidebar(false)
      },
      minimizeSidebar() {
        this.$sidebar.toggleMinimize()
      },
      toggleNavbar() {
        this.showNavbar = !this.showNavbar;
      },
    }
  }

</script>
<style>

</style>
