var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.disabled)?_c('input',{staticClass:"controls",attrs:{"id":"search-place","type":"text","placeholder":_vm.$t('Search Places')}}):_vm._e(),(_vm.defaultLocation.latitude)?_c('GmapMap',{ref:"Map",attrs:{"id":"map-picker","center":{lat:_vm.defaultLocation.latitude, lng: _vm.defaultLocation.longitude},"zoom":11,"options":{
             zoomControl: true,
             mapTypeControl: false,
             scaleControl: false,
             streetViewControl: false,
             rotateControl: false,
             fullscreenControl: true,
             disableDefaultUI: false
         }},on:{"click":_vm.clickedPosition}},[(_vm.model.latitude || _vm.location.latitude)?_c('GmapMarker',{ref:"myMarker",attrs:{"draggable":!this.disabled,"position":_vm.google && new _vm.google.maps.LatLng( _vm.location.latitude, _vm.location.longitude)},on:{"drag":_vm.draggedPosition}}):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }