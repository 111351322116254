export const __LocationOption = {
  latitude:null,
  longitude:null
}

export const __PhoneOption = {
  phone: null,
  phoneExt: '965',
  isValid: false,
  formattedNumber: null
}
