import {__localesModel, __localizedField} from "@/util/locales";

export const models = {
  locales: __localesModel,
  businessId:null,
}
export const options = {
}

export const fields = [
  __localizedField
];
